import React ,{ Component }from "react";
import { FaReact } from "react-icons/fa"
import { SiDjango, SiFirebase, SiGithub, SiPostgresql, SiAdobexd } from "react-icons/si"

const ServiceList = [
    {
        icon: <FaReact style = {{width: "70px", height: "70px"}}/>,
        link: 'https://es.reactjs.org/',
        color: '#61DBFB',
        title: 'React.js',
        description: 'Es una biblioteca Javascript de código abierto diseñada para crear interfaces de usuario. Es mantenido por Facebook y la comunidad de software libre.'
    },
    {
        icon: <SiDjango style = {{width: "70px", height: "70px"}}/>,
        color: '#092e20',
        link: 'https://www.djangoproject.com/',
        title: 'Django Rest Framework',
        description: 'Es un conjunto de herramientas potente y flexible para crear API web.'
    },
    {
        icon: <SiFirebase style = {{width: "70px", height: "70px"}}/>,
        color: '#FFCA28',
        link: 'https://firebase.google.com/',
        title: 'Google Firebase',
        description: 'Es una plataforma digital que se utiliza para facilitar el desarrollo de aplicaciones web o móviles de una forma efectiva, rápida y sencilla.'
    },
    { 
        icon: <SiGithub style = {{width: "70px", height: "70px"}}/>,
        color: '#ffffff',
        link: 'https://github.com/',
        title: 'Github',
        description: 'GitHub es una plataforma de desarrollo colaborativo de software para alojar proyectos utilizando el sistema de control de versiones Git.'
    },
    { 
        icon: <SiPostgresql style = {{width: "70px", height: "70px"}}/>,
        color: '#008bb9',
        link: 'https://www.postgresql.org/',
        title: 'PostgreSQL',
        description: 'PostgreSQL es un gestor de bases de datos relacional y orientado a objetos. Su licencia y desarrollo es de código abierto.'
    },
    {
        icon: <SiAdobexd style = {{width: "70px", height: "70px"}}/>,
        color: '#470137',
        link: 'https://www.adobe.com/mx/products/xd.html',
        title: 'Adobe Xd',
        description: 'Adobe XD es un software usado para crear y compartir interfaces, tanto para páginas webs como para aplicaciones.'
    },
]


class ServiceThree extends Component{
    render(){
        const {column} = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={`${val.link}`} target="_blank">
                                <div className="service service__style--2 text-left bg-gray">
                                    <div style = {{color: val.color, width: "100px", height:"100px"}}>
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
