import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Habilidades",
        // tab2 = "Awards",
        tab3 = "Experiencia",
        tab4 = "Educacion";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        {/* <Tab>{tab2}</Tab> */}
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">Html5, Css, Javascript</h6>
                                                    <ProgressBar now={80} />
                                                    <span className="label">80%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Base de datos </h6>
                                                    <ProgressBar now={70} />
                                                    <span className="label">70%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Liderazgo y trabajo en equipo</h6>
                                                    <ProgressBar now={80} />
                                                    <span className="label">80%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Capacidad de adaptacion</h6>
                                                    <ProgressBar now={90} />
                                                    <span className="label">90%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>


                                    {/* <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="/service">CSS Design Awards <span>- Winner</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Design nominees <span>- site of the day</span></a> 2013- 2014
                                               </li>
                                               <li>
                                                   <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel> */}



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://autopaquete.com/" target="_blank">Full Stack Developer<span> - Autopaquete</span></a> 2021 - Presente
                                               </li>
                                               {/* <li>
                                                   <a href="/service">Front-end Engineer<span> - Microsoft</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014
                                               </li>
                                               <li>
                                                   <a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014
                                               </li> */}
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://www.academlo.com/" target="_blank">Desarrollo web Full-stack<span> - Academlo</span></a> 2021
                                               </li>
                                               <li>
                                                   <a href="http://www.cutonala.udg.mx/" target="_blank">Ingenieria en ciencias computacionales<span> - UDG</span></a> 2020
                                               </li>
                                               {/* <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li>
                                               <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li> */}
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;