import React, { Component } from "react";
import FormStyle from './FormStyle';

class ContactThree extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Contactame</span>
                                <h2 className="title">Contratame.</h2>
                                <div className="im_address_inner">
                                    <div className="im_address">
                                        <span>Telefono:</span>
                                        <a className="link im-hover" href="tel:+52 33 1052 4928<">+52 3310524928</a>
                                    </div>
                                    <div className="im_address mt--5">
                                        <span>Correo:</span>
                                        <a className="link im-hover" href="mailto:developer@aris.com.mx">developer@aris.com.mx</a>
                                    </div>
                                </div>
                            </div>
                            <FormStyle />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;